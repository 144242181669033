import { styled } from "@mui/material";

const ContactDetailsList = styled("ul")(({ theme }) => {
  return {
    listStyle: "none",
    margin: 0,
    padding: 0,
    a: {
      textDecoration: "none",
      color: theme.palette.text.primary,
    },
  };
});

export default ContactDetailsList;
