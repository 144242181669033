import * as React from "react";
import { Link as RouterLink } from "react-router-dom";
import { useParams } from "react-router-dom";
import { Box, Link } from "@mui/material";

import { useUserData } from "../../services";

import URL from "./URL";
import VCard from "./VCard";

/**
 * The query parameters carring the qr code type
 * */
type QueryParameters = {
  type: string;
  index: string;
};

/**
 * Source code for QR code generator:
 * https://github.com/konstantin-nizhinskiy/qr-code-and-vcard/tree/master/src/prototype
 * */
const pageComponent = React.memo(function Page() {
  const userData = useUserData();
  const urlParams = useParams<QueryParameters>();

  const render = React.useCallback(() => {
    switch (urlParams.type?.toUpperCase()) {
      case "VCARD":
        return <VCard />;
      default: {
        if (!urlParams.type) {
          return <>UNKNOWN TYPE: {urlParams.type}</>;
        }

        const index = parseInt(urlParams.index ?? "0");
        if (isNaN(index)) {
          return <>INVALID INDEX: {urlParams.index}</>;
        }

        return <URL type={urlParams.type} index={index} />;
      }
    }
  }, [urlParams.index, urlParams.type]);

  return (
    <>
      {render()}
      <Box textAlign="center">
        <Link
          component={RouterLink}
          to={`/${userData.clientName}/${userData.userName}`}
        >
          Back to {userData.personnel.title} {userData.personnel.firstName}{" "}
          {userData.personnel.lastName}
        </Link>
      </Box>
    </>
  );
});

export default pageComponent;
