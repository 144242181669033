import * as React from "react";
import { Container } from "@mui/material";

import { useUserData } from "../../services";

import Apollo from "./Apollo";
import Aurora from "./Aurora";
import { QRButton } from "./QRButton";

const pageComponent = React.memo(function Page() {
  const userData = useUserData();

  const renderTemplate = () => {
    switch (userData.template.name) {
      case "Apollo":
        return <Apollo />;
      case "Aurora":
        return <Aurora />;
      default:
        return <>UNKNOWN TEMPLATE: &quot;{userData.template}&quot;</>;
    }
  };

  return (
    <Container maxWidth="md">
      {renderTemplate()}
      <QRButton />
    </Container>
  );
});

export default pageComponent;
