import * as React from "react";
import { AppInsightsProvider } from "@ami3go/diagnostics";

import AppContainer from "./AppContainer";
import CustomRouter from "./CustomRouter";
import { applicationSettings, createHistory } from "./utils";

// get history
const history = createHistory();

export default function App() {
  return (
    <CustomRouter history={history}>
      <AppInsightsProvider
        instrumentationKey={
          applicationSettings.applicationInsightsInstrumentationKey
        }
        history={history}
      >
        <AppContainer />
      </AppInsightsProvider>
    </CustomRouter>
  );
}
