import * as React from "react";
import { Typography } from "@mui/material";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faGlobe } from "@fortawesome/free-solid-svg-icons";

import { useUserDataHelpers } from "../../../hooks";
import { UserDataLink } from "../../../models";

import { ContactDetailsItem } from ".";

export type ContactDetailsItemForLinkProps = {
  link: UserDataLink;
};

export const ContactDetailsItemForLink = React.memo(function Component(
  props: ContactDetailsItemForLinkProps
) {
  const userDataHelpers = useUserDataHelpers();

  const [icon, setIcon] = React.useState<IconProp>(faGlobe);
  const [iconColour, setIconColour] = React.useState("");

  React.useEffect(() => {
    setIcon(userDataHelpers.getIconByType(props.link.type));
    setIconColour(userDataHelpers.getIconColourByType(props.link.type));
  }, [
    props.link,
    userDataHelpers.getIconByType,
    userDataHelpers.getIconColourByType,
  ]);

  return (
    <ContactDetailsItem icon={icon} iconColour={iconColour}>
      <Typography variant="body1">
        <a href={props.link.url}>{props.link.label}</a>
      </Typography>
    </ContactDetailsItem>
  );
});
