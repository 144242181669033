import React from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { useAppInsights } from "@ami3go/diagnostics";
import {
  createTheme,
  responsiveFontSizes,
  Theme,
  ThemeProvider,
} from "@mui/material";
import WebFont from "webfontloader";

import {
  UserDataCompany,
  UserDataPersonnel,
  UserDataTemplate,
} from "../models";

/**
 * The query parameters carring the users identifier
 * */
type QueryParameters = {
  clientName: string;
  userName: string;
};

/**
 * The user data state
 * */
type UserDataState = {
  theme: Theme;
  template: UserDataTemplate;
  clientName: string;
  userName: string;
  personnel: UserDataPersonnel;
  company: UserDataCompany;
};

/**
 * The default state
 * */
const defaultState: UserDataState = {
  theme: createTheme(),
  template: {
    name: "Apollo",
    googleFonts: [],
    data: {},
  },
  clientName: "",
  userName: "",
  personnel: {
    title: "",
    firstName: "",
    lastName: "",
    position: "",
    links: [],
  },
  company: {
    name: "",
    links: [],
    address: [],
  },
};

// Create a context
const UserDataContext = React.createContext<UserDataState>(defaultState);

/*
 * Props for UserDataProvider
 * */
type UserDataProviderProps = {
  children: JSX.Element;
};

/**
 * This provider will be used to get the user data based on the pops
 * @param props
 */
function UserDataProvider(props: UserDataProviderProps) {
  const logger = useAppInsights();
  const urlParams = useParams<QueryParameters>();

  const [state, setState] = React.useState(defaultState);
  const [loadedFonts, setLoadedFonts] = React.useState<string[]>([]);
  const [iconUrl, setIconUrl] = React.useState("");

  React.useEffect(() => {
    const newState = getData(urlParams.clientName, urlParams.userName); // TODO: get this from an API
    setState(newState);

    logger.info(
      `Loaded data for ${newState.personnel.firstName} ${newState.personnel.lastName} @ ${newState.company.name}`
    );
  }, [urlParams.clientName, urlParams.userName]);

  const getData = (clientName?: string, userName?: string): UserDataState => {
    logger.info(`Loading data for ${userName} @ ${clientName}`);

    switch (clientName?.toUpperCase()) {
      case "AMI3GO": {
        const theme = responsiveFontSizes(
          createTheme({
            palette: {
              primary: {
                main: "#c6e3d7",
                light: "#f9ffff",
                dark: "#95b1a6",
                contrastText: "#223b54",
              },
              secondary: {
                main: "#263c53",
                light: "#51667f",
                dark: "#00162a",
                contrastText: "#fff",
              },
              text: {
                primary: "#33393f",
              },
            },
            typography: {
              fontFamily: ["Questrial", "sans-serif"].join(","),
              fontSize: 18,
              h1: {
                fontSize: 70,
                fontWeight: "medium",
                letterSpacing: "0.08em",
              },
              h2: {
                fontSize: 40,
                fontWeight: "bold",
                textTransform: "uppercase",
              },
            },
          })
        );

        const template = {
          name: "Apollo",
          googleFonts: ["Questrial"],
          data: {
            headingColour: "#263C53",
            subHeadingColour: "#263C53",
            iconColour: "#9EB8D4",
          },
        } as UserDataTemplate;

        const company = {
          name: "AMI3GO Ltd",
          links: [
            {
              type: "web",
              label: "www.ami3go.com",
              url: "https://www.ami3go.com",
            },
            {
              type: "mailto",
              label: "enquiry@ami3go.com",
              url: "mailto:enquiry@ami3go.com",
              value: "enquiry@ami3go.com",
            },
            {
              type: "tel",
              label: "+44 (0)161 3271825",
              url: "tel:+441613271825",
              value: "+441613271825",
            },
            {
              type: "linkedin",
              label: "ami3go",
              url: "https://linkedin.com/company/ami3go",
            },
            {
              type: "facebook",
              label: "ami3go",
              url: "https://facebook.com/ami3go",
            },
            {
              type: "twitter",
              label: "@ami3goltd",
              url: "https://twitter.com/ami3go",
            },
          ],
          address: [
            "Colony",
            "5 Piccadilly Place",
            "Manchester",
            "M1 3BR",
            "United Kingdom",
          ],
        } as UserDataCompany;

        switch (userName?.toUpperCase()) {
          case "DWILSON": {
            return {
              theme: theme,
              template: template,
              clientName: clientName ?? "",
              userName: userName ?? "",
              company: company,
              personnel: {
                title: "",
                firstName: "David",
                lastName: "Wilson",
                position: "Creative Director",
                links: [
                  {
                    type: "mailto",
                    label: "david.wilson@ami3go.com",
                    url: "mailto:david.wilson@ami3go.com",
                    value: "david.wilson@ami3go.com",
                  },
                  {
                    type: "tel",
                    label: "+44 (0)7759 506528",
                    url: "tel:+447759506528",
                    value: "+447759506528",
                  },
                  {
                    type: "linkedin",
                    label: "ami3go-david-wilson",
                    url: "https://www.linkedin.com/in/ami3go-david-wilson",
                  },
                  {
                    type: "facebook",
                    label: "zxynk",
                    url: "https://facebook.com/zxynk",
                  },
                  {
                    type: "twitter",
                    label: "@zxynk",
                    url: "https://twitter.com/zxynk",
                  },
                ],
              },
            };
          }
          case "AMARANDI": {
            return {
              theme: theme,
              template: template,
              clientName: clientName ?? "",
              userName: userName ?? "",
              company: company,
              personnel: {
                title: "",
                firstName: "Ashkan",
                lastName: "Marandi",
                position: "Technical Director",
                links: [
                  {
                    type: "mailto",
                    label: "ashkan.marandi@ami3go.com",
                    url: "mailto:ashkan.marandi@ami3go.com",
                    value: "ashkan.marandi@ami3go.com",
                  },
                  {
                    type: "tel",
                    label: "+44 (0)7870 506528",
                    url: "tel:+447870359045",
                    value: "+447870359045",
                  },
                  {
                    type: "linkedin",
                    label: "ash-marandi-72141875",
                    url: "https://linkedin.com/in/ash-marandi-72141875",
                  },
                  {
                    type: "facebook",
                    label: "ash.marandi",
                    url: "https://facebook.com/ash.marandi",
                  },
                  {
                    type: "twitter",
                    label: "@marandiash",
                    url: "https://twitter.com/marandiash",
                  },
                ],
              },
            };
          }
          case "RCRONSHAW": {
            return {
              theme: theme,
              template: template,
              clientName: clientName ?? "",
              userName: userName ?? "",
              company: company,
              personnel: {
                title: "",
                firstName: "Robin",
                lastName: "Cronshaw",
                position: "Digital Strategy Director",
                links: [
                  {
                    type: "mailto",
                    label: "robin.cronshaw@ami3go.com",
                    url: "mailto:robin.cronshaw@ami3go.com",
                    value: "robin.cronshaw@ami3go.com",
                  },
                  {
                    type: "tel",
                    label: "+44 (0)7759 186009",
                    url: "tel:+447759186009",
                    value: "+447759186009",
                  },
                  {
                    type: "linkedin",
                    label: "david-wilson-032449195",
                    url: "https://linkedin.com/in/robin-cronshaw-042a3822",
                  },
                ],
              },
            };
          }
          default:
            return defaultState;
        }
      }
      case "AFCHEMPHARM": {
        const theme = responsiveFontSizes(
          createTheme({
            palette: {
              primary: {
                main: "#006c7c",
              },
              secondary: {
                main: "#7b1b5c",
              },
              text: {
                primary: "#3a3a3a",
              },
            },
            typography: {
              fontFamily: ["PT Sans", "sans-serif"].join(","),
              fontSize: 18,
              h1: {
                fontSize: 70,
                fontWeight: "medium",
                letterSpacing: "0.08em",
              },
              h2: {
                fontSize: 40,
                fontWeight: "bold",
                textTransform: "uppercase",
              },
            },
          })
        );

        const template = {
          name: "Aurora",
          googleFonts: ["PT Sans"],
          data: {
            headingColour: "#006c7c",
            subHeadingColour: "#808080",
            iconColour: "#7b1b5c",
          },
        } as UserDataTemplate;

        const company = {
          name: "AF ChemPharm",
          links: [
            {
              type: "web",
              label: "www.afchempharm.co.uk",
              url: "https://www.afchempharm.co.uk",
            },
            {
              type: "mailto",
              label: "enquiries@afchempharm.co.uk",
              url: "mailto:enquiries@afchempharm.co.uk",
              value: "enquiries@afchempharm.co.uk",
            },
            {
              type: "tel",
              label: "+44 (0)114 3277 279",
              url: "tel:+441143277279",
              value: "+441143277279",
            },
            {
              type: "linkedin",
              label: "ami3go",
              url: "https://linkedin.com/company/af-chempharm",
            },
            {
              type: "twitter",
              label: "@AFChemPharm",
              url: "https://twitter.com/AFChemPharm",
            },
          ],
          address: [
            "Bailey House",
            "3 Bailey Street",
            "Sheffield",
            "S1 4EH",
            "United Kingdom",
          ],
        } as UserDataCompany;

        switch (userName?.toUpperCase()) {
          case "AFALLAH": {
            return {
              theme: theme,
              template: template,
              clientName: clientName ?? "",
              userName: userName ?? "",
              company: company,
              personnel: {
                title: "Dr",
                firstName: "Asad",
                lastName: "Fallah",
                position: "Managing director",
                links: [
                  {
                    type: "mailto",
                    label: "a.fallah@afchempharm.co.uk",
                    url: "mailto:a.fallah@afchempharm.co.uk",
                    value: "a.fallah@afchempharm.co.uk",
                  },
                  {
                    type: "linkedin",
                    label: "asad-fallah-0989bb14",
                    url: "https://linkedin.com/in/asad-fallah-0989bb14",
                  },
                ],
              },
            };
          }
          case "RQUYOUM": {
            return {
              theme: theme,
              template: template,
              clientName: clientName ?? "",
              userName: userName ?? "",
              company: company,
              personnel: {
                title: "Dr",
                firstName: "Ruhksana",
                lastName: "Quyoum",
                position: "Director",
                links: [
                  {
                    type: "mailto",
                    label: "r.quyoum@afchempharm.co.uk",
                    url: "mailto:r.quyoum@afchempharm.co.uk",
                    value: "r.quyoum@afchempharm.co.uk",
                  },
                  {
                    type: "linkedin",
                    label: "ruhksana-quyoum-629094112",
                    url: "https://linkedin.com/in/ruhksana-quyoum-629094112",
                  },
                ],
              },
            };
          }
          case "AMARANDI": {
            return {
              theme: theme,
              template: template,
              clientName: clientName ?? "",
              userName: userName ?? "",
              company: company,
              personnel: {
                title: "Dr",
                firstName: "Arshnous",
                lastName: "Marandi",
                position: "Computational Medicinal Chemist",
                links: [
                  {
                    type: "mailto",
                    label: "a.marandi@afchempharm.co.uk",
                    url: "mailto:a.marandi@afchempharm.co.uk",
                    value: "a.marandi@afchempharm.co.uk",
                  },
                  {
                    type: "linkedin",
                    label: "arshnous-marandi-08182288",
                    url: "https://linkedin.com/in/arshnous-marandi-08182288",
                  },
                ],
              },
            };
          }
          default:
            return defaultState;
        }
      }
      default:
        return defaultState;
    }
  };

  /**
   * On template fonts changed, load the web fonts from Google
   * */
  React.useEffect(() => {
    const newFonts = state.template.googleFonts.filter(
      (i) => !loadedFonts.includes(i)
    );

    if (newFonts.length === 0) {
      return;
    }
    WebFont.load({ google: { families: newFonts } });
    setLoadedFonts([...loadedFonts, ...newFonts]);
  }, [state.template.googleFonts]);

  /**
   * On client name changed, set the icon URL
   * */
  React.useEffect(() => {
    setIconUrl(`/assets/${state.clientName}/icons`);
  }, [state.clientName]);

  return (
    <UserDataContext.Provider value={state}>
      <Helmet>
        <title>
          {state.personnel.title} {state.personnel.firstName}{" "}
          {state.personnel.lastName} @ {state.company.name}
        </title>
        <meta
          name="description"
          content={
            state.personnel.title +
            " " +
            state.personnel.firstName +
            " " +
            state.personnel.lastName +
            " @ " +
            state.company.name +
            " - Digital Business Card by AMI3GO Ltd"
          }
        />

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={iconUrl + "/apple-touch-icon.png"}
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href={iconUrl + "/favicon-32x32.png"}
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href={iconUrl + "/favicon-16x16.png"}
        />
        <link rel="manifest" href={iconUrl + "/site.webmanifest"} />
        <link
          rel="mask-icon"
          href={iconUrl + "/safari-pinned-tab.svg"}
          color={state.theme.palette.primary.main}
        />
        <link rel="shortcut icon" href={iconUrl + "/favicon.ico"} />
        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta
          name="msapplication-config"
          content={iconUrl + "/browserconfig.xml"}
        />
        <meta name="theme-color" content="#ffffff" />
      </Helmet>

      <ThemeProvider theme={state.theme}>{props.children}</ThemeProvider>
    </UserDataContext.Provider>
  );
}

/**
 * This hook can be used to expose the context
 * @returns UserDataContext
 * */
function useUserData() {
  const context = React.useContext(UserDataContext);

  if (context === undefined) {
    throw new Error("useUserData must be used within a UserDataProvider");
  }

  return context;
}

export { UserDataProvider, useUserData };
