import * as React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Link, Typography } from "@mui/material";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faGlobe } from "@fortawesome/free-solid-svg-icons";

import { useUserDataHelpers } from "../../../hooks";
import { UserDataLink, UserDataLinkType } from "../../../models";
import { useUserData } from "../../../services";

import { ContactDetailsItem } from ".";

export type ContactDetailsItemForLinkTypeProps = {
  type: UserDataLinkType;
};

export const ContactDetailsItemForLinkType = React.memo(function Component(
  props: ContactDetailsItemForLinkTypeProps
) {
  const userData = useUserData();
  const userDataHelpers = useUserDataHelpers();

  const [dataLinks, setDataLinks] = React.useState<UserDataLink[]>([]);
  const [icon, setIcon] = React.useState<IconProp>(faGlobe);
  const [iconColour, setIconColour] = React.useState("");

  React.useEffect(() => {
    setDataLinks(userDataHelpers.getLinksByType(props.type));
    setIcon(userDataHelpers.getIconByType(props.type));
    setIconColour(userDataHelpers.getIconColourByType(props.type));
  }, [userData, props.type]);

  if (dataLinks.length === 0) {
    return <></>;
  }

  return (
    <ContactDetailsItem icon={icon} iconColour={iconColour}>
      <>
        {dataLinks.map((item, index) => {
          return (
            <Typography key={index} variant="body1">
              <Link
                component={RouterLink}
                to={`/${userData.clientName}/${userData.userName}/qr/${item.type}/${index}`}
              >
                {item.label}
              </Link>
            </Typography>
          );
        })}
      </>
    </ContactDetailsItem>
  );
});
