import * as React from "react";
import { Outlet, Route, Routes } from "react-router-dom";
import { Box, Container, CssBaseline } from "@mui/material";

import { Home, QR, Template } from "./pages";
import { UserDataProvider } from "./services";

function RouteWrapper() {
  return (
    <UserDataProvider>
      <Outlet />
    </UserDataProvider>
  );
}

export default function AppContainer() {
  //if (isLoading) {
  //  return (
  //    <Backdrop
  //      open={true}
  //      transitionDuration={500}
  //      style={{
  //        backgroundColor: "#333a41",
  //        color: "#c6e3d7",
  //        zIndex: theme.zIndex.tooltip + 1,
  //      }}
  //    >
  //      <Box
  //        p={2}
  //        display="flex"
  //        flexGrow={1}
  //        justifyContent="center"
  //        textAlign="center"
  //      >
  //        <Box my={5}>
  //          <CircularProgress size="5em" thickness={5} />
  //        </Box>
  //      </Box>
  //    </Backdrop>
  //  );
  //}

  return (
    <Box
      sx={{
        minHeight: "100vh",
        flexDirection: "column",
        display: "flex",
        flex: "1 1 auto",
      }}
    >
      <Container
        maxWidth={false}
        disableGutters
        sx={{
          flexDirection: "column",
          display: "flex",
          flex: "1 0 auto",
          padding: 0,
        }}
      >
        <Box>
          <Routes>
            <Route path=":clientName/:userName" element={<RouteWrapper />}>
              <Route path="qr/:type" element={<QR />}>
                <Route path=":index" element={<QR />} />
                <Route path="" element={<QR />} />
              </Route>
              <Route path="" element={<Template />} />
            </Route>
            <Route path="*" element={<Home />} />
          </Routes>
        </Box>
      </Container>

      <CssBaseline />
    </Box>
  );
}
