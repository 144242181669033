import * as React from "react";
import { Box, Container, Typography } from "@mui/material";
import qrCode from "qr-code-and-vcard/dist/QrCode";

import { useUserDataHelpers } from "../../hooks";
import { UserDataLink, UserDataLinkType } from "../../models";
import {
  ContactDetailsItemForLink,
  ContactDetailsList,
} from "../Template/Common";

type URLProps = {
  type: string;
  index: number;
};

const component = React.memo(function component(props: URLProps) {
  const userDataHelpers = useUserDataHelpers();

  const [imageSource, setImageSource] = React.useState("");
  const [link, setLink] = React.useState<UserDataLink | null>(null);

  React.useEffect(() => {
    const links = userDataHelpers.getLinksByType(
      props.type as UserDataLinkType
    );
    setLink(links[props.index] ?? null);
  }, [props.type, props.index, userDataHelpers.getLinksByType]);

  React.useEffect(() => {
    if (link === null) {
      setImageSource("");
      return;
    }

    const qrAsSvg = qrCode.createQr({
      data: link.url,
      typeNumber: 3,
      typeElement: "createSvg",
    });

    const document = new DOMParser().parseFromString(qrAsSvg, "text/html");
    const svgElement = document.getElementsByTagName("svg")[0];

    const decoded = unescape(encodeURIComponent(svgElement.outerHTML)); // Remove any characters outside the Latin1 range
    const base64 = btoa(decoded); // Now we can use btoa to convert the svg to base64

    setImageSource(`data:image/svg+xml;base64,${base64}`);
  }, [link]);

  return (
    <Container maxWidth="sm">
      {link && (
        <Box display="flex" justifyContent="center">
          <ContactDetailsList>
            <ContactDetailsItemForLink link={link} />
            <Typography variant="body1" textAlign="center">
              Scan the QR code or click the link above
            </Typography>
          </ContactDetailsList>
        </Box>
      )}
      {imageSource && <img src={imageSource} style={{ width: "100%" }} />}
    </Container>
  );
});

export default component;
