import * as React from "react";
import { Box, Container, Typography } from "@mui/material";
import qrCode from "qr-code-and-vcard/dist/QrCode";

import { useUserDataHelpers } from "../../hooks";
import { useUserData } from "../../services";

const component = React.memo(function component() {
  const userData = useUserData();
  const userDataHelpers = useUserDataHelpers();

  const [imageSource, setImageSource] = React.useState("");

  React.useEffect(() => {
    const vcard = {
      version: "3.0",
      lastName: userData.personnel.lastName,
      firstName: userData.personnel.firstName,
      namePrefix: userData.personnel.title,
      organization: userData.company.name,
      workPhone: userData.company.links.find((i) => i.type === "tel")?.value,
      cellPhone: userData.personnel.links.find((i) => i.type === "tel")?.value,
      title: userData.personnel.position,
      email: userData.personnel.links.find((i) => i.type === "mailto")?.value,
      workEmail: userData.company.links.find((i) => i.type === "mailto")?.value,
      workUrl: userData.company.links.find((i) => i.type === "web")?.url,
      workAddress: {
        label: "Work Address",
        street: userDataHelpers.getCompanyAddressStreet(),
        city: userDataHelpers.getCompanyAddressTown(),
        postalCode: userDataHelpers.getCompanyAddressPostcode(),
        countryRegion: userDataHelpers.getCompanyAddressCountry(),
      },
      socialUrls: {
        facebook: userDataHelpers.getLinksByType("facebook")[0]?.label,
        linkedIn: userDataHelpers.getLinksByType("linkedin")[0]?.label,
        twitter: userDataHelpers.getLinksByType("twitter")[0]?.label,
      },
    };

    const qrAsSvg = qrCode.createVCardQr(vcard, {
      typeNumber: 25,
      typeElement: "createSvg",
    });

    const document = new DOMParser().parseFromString(qrAsSvg, "text/html");
    const svgElement = document.getElementsByTagName("svg")[0];

    const decoded = unescape(encodeURIComponent(svgElement.outerHTML)); // Remove any characters outside the Latin1 range
    const base64 = btoa(decoded); // Now we can use btoa to convert the svg to base64

    setImageSource(`data:image/svg+xml;base64,${base64}`);
  }, [userData]);

  return (
    <Container maxWidth="sm">
      <Box display="flex" justifyContent="center">
        <Typography variant="body1" textAlign="center">
          Scan the QR code to add{" "}
          <b>{userDataHelpers.getPersonnelFullName()}</b> of{" "}
          <b>{userData.company.name}</b> to your contacts
        </Typography>
      </Box>
      {imageSource && <img src={imageSource} style={{ width: "100%" }} />}
    </Container>
  );
});

export default component;
