import * as React from "react";
import {
  faFacebook,
  faLinkedinIn,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { faAt, faGlobe, faPhone } from "@fortawesome/free-solid-svg-icons";

import { UserDataLinkType } from "../models";
import { useUserData } from "../services";

export default function useUserDataHelpers() {
  const userData = useUserData();

  const getLinksByType = React.useCallback(
    (type: UserDataLinkType) => {
      return userData.personnel.links
        .filter((l) => l.type === type)
        .concat(userData.company.links.filter((l) => l.type === type));
    },
    [userData]
  );

  const getIconByType = React.useCallback(
    (type: UserDataLinkType) => {
      switch (type) {
        case "tel":
          return faPhone;
        case "mailto":
          return faAt;
        case "facebook":
          return faFacebook;
        case "twitter":
          return faTwitter;
        case "linkedin":
          return faLinkedinIn;
        default:
          return faGlobe;
      }
    },
    [userData]
  );

  const getIconColourByType = React.useCallback(
    (type: UserDataLinkType) => {
      switch (type) {
        case "linkedin":
          return "#0077b5";
        case "twitter":
          return "#55acee";
        case "facebook":
          return "#3b5998";
        default:
          return userData.template.data.iconColour;
      }
    },
    [userData]
  );

  const getCompanyAddressCountry = React.useCallback(() => {
    return userData.company.address[userData.company.address.length - 1];
  }, [userData]);

  const getCompanyAddressPostcode = React.useCallback(() => {
    return userData.company.address[userData.company.address.length - 2];
  }, [userData]);

  const getCompanyAddressTown = React.useCallback(() => {
    return userData.company.address[userData.company.address.length - 3];
  }, [userData]);

  const getCompanyAddressStreet = React.useCallback(() => {
    const numberOfLines = userData.company.address.length - 3;
    return userData.company.address.slice(0, numberOfLines).join("\n");
  }, [userData]);

  const getPersonnelFullName = React.useCallback(() => {
    const nameParts = [
      userData.personnel.title,
      userData.personnel.firstName,
      userData.personnel.lastName,
    ];
    return nameParts.filter((i) => i.length !== 0).join(" ");
  }, [userData]);

  return {
    getLinksByType,
    getIconByType,
    getIconColourByType,
    getCompanyAddressCountry,
    getCompanyAddressPostcode,
    getCompanyAddressTown,
    getCompanyAddressStreet,
    getPersonnelFullName,
  };
}
