import * as React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { faLocationPin } from "@fortawesome/free-solid-svg-icons";

import { useUserDataHelpers } from "../../../hooks";
import { useUserData } from "../../../services";
import {
  ContactDetailsItem,
  ContactDetailsItemForLinkType,
  ContactDetailsList,
} from "../Common";

const pageComponent = React.memo(function Page() {
  const userData = useUserData();
  const userDataHelpers = useUserDataHelpers();

  const renderColumnA = () => {
    return (
      <ContactDetailsList>
        <ContactDetailsItemForLinkType type="mailto" />
        <ContactDetailsItemForLinkType type="tel" />
        <ContactDetailsItemForLinkType type="web" />

        {userData.company.address.length !== 0 && (
          <ContactDetailsItem
            icon={faLocationPin}
            iconColour={userData.template.data.iconColour}
          >
            <Typography variant="body1">
              {userData.company.address.map((line, index) => {
                return (
                  <>
                    {index !== 0 && <br />}

                    <>{line}</>
                  </>
                );
              })}
            </Typography>
          </ContactDetailsItem>
        )}
      </ContactDetailsList>
    );
  };

  const renderColumnB = () => {
    return (
      <ContactDetailsList>
        <ContactDetailsItemForLinkType type="linkedin" />
        <ContactDetailsItemForLinkType type="twitter" />
        <ContactDetailsItemForLinkType type="facebook" />
      </ContactDetailsList>
    );
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Box
            style={{
              minHeight: 200,
              width: 200,
              background: `url('/assets/${userData.clientName}/company/logo.webp') no-repeat center center/contain`,
            }}
          />
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Typography
          variant="h1"
          gutterBottom
          sx={{
            color: userData.template.data.headingColour,
            textAlign: "center",
          }}
        >
          {userDataHelpers.getPersonnelFullName()}
        </Typography>
        <Typography
          variant="h2"
          sx={{
            color: userData.template.data.subHeadingColour,
            textAlign: "center",
          }}
        >
          {userData.personnel.position}
        </Typography>
      </Grid>

      <Grid item xs={12} sm={6}>
        {renderColumnA()}
      </Grid>

      <Grid item xs={12} sm={6}>
        {renderColumnB()}
      </Grid>
    </Grid>
  );
});

export default pageComponent;
