import * as React from "react";
import { Box, styled } from "@mui/material";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type StyledContactDetailItemProps = {
  iconColour: string;
};
const StyledContactDetailItem = styled("li")<StyledContactDetailItemProps>(
  (props) => {
    const margin = props.theme.spacing(2);
    return {
      marginTop: margin,
      marginBottom: margin,
      marginLeft: 0,
      marginRight: 0,
      padding: 0,
      "& .svg-inline--fa": {
        fontSize: "2em",
        marginRight: margin,
        minWidth: 30,
        color: props.iconColour,
      },
    };
  }
);

export type ContactDetailsItemProps = {
  icon: IconProp;
  iconColour: string;
  children: React.ReactNode;
};

export const ContactDetailsItem = React.memo(function Component(
  props: ContactDetailsItemProps
) {
  return (
    <StyledContactDetailItem iconColour={props.iconColour}>
      <Box alignItems="center" display="flex">
        <FontAwesomeIcon icon={props.icon} />
        <Box>{props.children}</Box>
      </Box>
    </StyledContactDetailItem>
  );
});
