import * as React from "react";
import { useNavigate } from "react-router-dom";
import { Fab, styled } from "@mui/material";
import { faQrcode } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useUserData } from "../../services";

const StyledQRButton = styled(Fab)((props) => {
  const margin = props.theme.spacing(2);
  return {
    position: "fixed",
    bottom: margin,
    right: margin,
  };
});

export const QRButton = React.memo(function Component() {
  const navigate = useNavigate();
  const userData = useUserData();

  const onViewQrCode = () => {
    navigate(`/${userData.clientName}/${userData.userName}/qr/vcard`);
  };

  return (
    <StyledQRButton color="primary" onClick={onViewQrCode}>
      <FontAwesomeIcon icon={faQrcode} />
    </StyledQRButton>
  );
});
